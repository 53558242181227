@tailwind base;

@tailwind components;

@tailwind utilities;
.retro-btn {
  position: relative;
  padding: 0.75rem 1.5rem;
  font-family: "Press Start 2P", cursive;
  color: #00ff41;
  background-color: black;
  border: 3px solid #00ff41;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  box-shadow:
    0 0 10px rgba(0, 255, 65, 0.5),
    inset 0 0 10px rgba(0, 255, 65, 0.5);
}

.retro-btn:hover {
  color: black;
  background-color: #00ff41;
  box-shadow:
    0 0 20px rgba(0, 255, 65, 1),
    inset 0 0 20px rgba(0, 255, 65, 1);
}

.retro-btn::before,
.retro-btn::after {
  content: "";
  position: absolute;
  border: 2px solid #00ff41;
  transition: all 0.3s ease;
}

.retro-btn::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(-10px, -10px);
}

.retro-btn::after {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translate(10px, 10px);
}

.retro-btn:hover::before {
  transform: translate(10px, 10px);
}

.retro-btn:hover::after {
  transform: translate(-10px, -10px);
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes rainbow {
  0% {
    color: #ff0000;
  }
  14% {
    color: #ff7f00;
  }
  28% {
    color: #ffff00;
  }
  42% {
    color: #00ff00;
  }
  57% {
    color: #0000ff;
  }
  71% {
    color: #4b0082;
  }
  85% {
    color: #9400d3;
  }
  100% {
    color: #ff0000;
  }
}

.rainbow-text {
  animation: rainbow 5s linear infinite;
}

.container {
  width: 100%;
  margin: auto;
}

@media (min-width: 768px) {
  .container {
    width: 70%;
  }
}

.main-title {
  @apply text-xl md:text-2xl font-tetris mb-4 text-center md:text-left text-white;
}